.map-container {
  position: relative;
  width: 85%;
  margin: 0 auto;
  overflow: hidden; /* 핀이 확대될 때 영역을 넘어가지 않도록 설정 */
}

canvas {
  width: 100%;
  height: auto;
  max-height: 85vh; /* 화면 세로의 85%를 넘지 않도록 설정 */
  transition: transform 0.1s; /* 확대/축소 및 이동 시 부드러운 전환 효과 */
  touch-action: none; /* 터치 이벤트가 다른 요소에 영향을 미치지 않도록 설정 */
}

.pin {
  background-color: #4285F4;
  border-radius: 8px;
  color: #FFFFFF;
  font-size: 14px;
  padding: 10px 15px;
  position: absolute;
  transform: translate(-50%, -100%);
  cursor: pointer;
}

.pin::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translate(-50%, 0);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #4285F4;
}

.search-container {
  max-height: 85vh;
  overflow-y: auto;
}

.icon-links {
  display: flex;
  gap: 10px;
}

.icon-link {
  color: inherit;
  text-decoration: none;
  font-size: 1.5rem;
}

.test {
  position: ab;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
}

/* 반응형 레이아웃 */
@media (min-aspect-ratio: 4/3) {
  .row {
    flex-direction: row;
  }

  .search-container {
    width: 35%;
    margin-left: 10px;
    margin-top: 0;
  }

  .map-container {
    width: 70%;
  }
}

/* 검색창과 버튼을 나란히 정렬 */
@media (max-width: 768px) {
  .form-inline .form-group,
  .form-inline .btn {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }

  .form-inline .form-group {
    margin-right: 10px;
  }

  .form-inline {
    text-align: center;
  }
}

.middle-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.canvas-buttons {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
}

.canvas-pin-buttons {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
}

.canvas-button {
  background: rgba(255, 255, 255, 0.8);
  border: none;
  padding: 10px;
  margin: 5px;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.canvas-button:hover {
  background: rgba(255, 255, 255, 1);
}

